import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

export const MeasureToolPanel = styled.div`
  background-color: ${sassVars.background};
  position: absolute;
  top: 142px;
  right: 103px;
  padding-top: 3px;
  padding-left: 4px;
  padding-right: 4px;
  height: 30px;
  z-index: 9;
  border-radius: 5px;
  box-shadow: '0 2px 4px rgba(0, 0, 0, 0.2)';
  cursor: pointer;
`;