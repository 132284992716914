import { NoiseMonitor } from 'src/@realtime/queries';
import { StreamedNoiseSample } from 'src/@realtime/types';

export interface NmtState {
  monitors: NoiseMonitor[];
  hasLoaded: boolean;
  samples: Record<string, StreamedNoiseSample[]>;
}

export enum NmtActionType {
  ADD_MONITORS = 'ADD_MONITORS',
  REMOVE_MONITORS = 'REMOVE_MONITORS',
  ADD_NOISE_SAMPLE = 'ADD_NOISE_SAMPLE',
}

// Action types
export type NmtAction =
  | {
      type: NmtActionType.ADD_MONITORS;
      payload: NoiseMonitor[];
    }
  | {
      type: NmtActionType.REMOVE_MONITORS;
      payload: NoiseMonitor[];
    }
  | {
      type: NmtActionType.ADD_NOISE_SAMPLE;
      payload: StreamedNoiseSample[];
    };
