import { CircleLayer } from 'react-map-gl';
import { SelectionLayerIds } from './constants';
import styled from 'styled-components/macro';

export const selectedAircraftCircleStyle: CircleLayer = {
  id: SelectionLayerIds.selectedAircraftCircle,
  type: 'circle',
  paint: {
    'circle-radius': 0, // Initial radius
    'circle-stroke-width': 0, // No stroke
    'circle-color': '#ffffff', // Filled white color
    'circle-opacity': 0.8, // Visible during animation
  },
};

export const FlightTagContentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const FlightTagContentsTailImg = styled.div`
  width: 40px; /* Fixed width for the image column */
  height: 40px; /* Fixed height for square appearance */
`;

export const FlightTagContentsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;
