import { NmtState, NmtAction, NmtActionType } from './types';

export const nmtReducer = (state: NmtState, action: NmtAction): NmtState => {
  switch (action.type) {
    case NmtActionType.ADD_MONITORS:
      return {
        ...state,
        hasLoaded: true,
        monitors: [...state.monitors, ...action.payload],
      };
    case NmtActionType.REMOVE_MONITORS:
      return {
        ...state,
        monitors: state.monitors.filter(monitor => !action.payload.includes(monitor)),
      };
    case NmtActionType.ADD_NOISE_SAMPLE:
      return {
        ...state,
        samples: {
          ...state.samples,
          [action.payload[0].deviceLocationId]: [
            ...(state.samples[action.payload[0].deviceLocationId] || []),
            ...action.payload,
          ],
        },
      };
    default:
      return state;
  }
};
