import React, { useMemo, useState } from 'react';
import { Source, Layer } from 'react-map-gl';
import { ToolTipSelectionLayer } from '../flightSelectionLayer/toolTipSelectionLayer';

// Contexts
import { MapEventTypes } from 'src/@realtime/contexts/map';

// Hooks
import { useBuildFlightTails } from './hooks';
import { useAddMapEventFunction } from 'src/@realtime/hooks';

// Functions
import { queryMapFeaturesByIds } from 'src/@realtime/utils';

// Constants
import { FlightTailLayerIds } from '../flightTailLayer/constants';
import { selectedTailStyle, defaultTailStyle, selectedHiddenTailStyle } from './styles';
import { FlightLayerIds } from '../flightLayer/constants';

// Types
import { TailLineStringProperties } from 'src/@realtime/types/flight';
import { findNearestPointWithAltitude } from 'src/@realtime/utils';

export const FlightTailLayer: React.FC = () => {
  const flightTailsData = useBuildFlightTails();
  if (!flightTailsData) {
    return null;
  }

  const [flightTails, selectedFlightTails] = flightTailsData;
  const [tooltipData, setTooltipData] = useState<{
    coordinates: GeoJSON.Position;
    altitude: number;
    trackId: string;
  }>(null);

  const hoverAircraftFunction = useMemo(
    () => ({
      id: 'my-map-aircraft-hover',
      type: MapEventTypes.HOVER,
      event: (event: mapboxgl.MapLayerMouseEvent) => {
        const { target, point } = event;
        const features = queryMapFeaturesByIds(target, point, [
          FlightLayerIds.flightLayer,
          FlightTailLayerIds.selectedHiddenTail,
        ]); // Add a layer for aircraft
        if (features[0]) {
          if (features[0].properties) {
            if (features[0].geometry.type === 'Point') {
              const feature = (features[0] as unknown) as GeoJSON.Feature<
                GeoJSON.Point,
                {
                  altitude: number;
                  trackId: string;
                }
              >;
              setTooltipData({
                coordinates: feature.geometry.coordinates,
                altitude: feature.properties.altitude,
                trackId: feature.properties.trackId,
              });
            } else if (features[0].geometry.type === 'LineString') {
              const feature = (features[0] as unknown) as GeoJSON.Feature<
                GeoJSON.LineString,
                TailLineStringProperties
              >;
              const pointPosition = findNearestPointWithAltitude(event, feature);
              setTooltipData({
                coordinates: pointPosition.coordinates,
                altitude: pointPosition.altitude,
                trackId: feature.properties.trackId,
              });
            }
          }
        } else {
          setTooltipData(null);
        }
      },
    }),
    []
  );

  useAddMapEventFunction(hoverAircraftFunction);

  return (
    <>
      <Source id={FlightTailLayerIds.defaultTail} type="geojson" data={flightTails}>
        <Layer {...defaultTailStyle} />
      </Source>
      <Source id={FlightTailLayerIds.selectedTail} type="geojson" data={selectedFlightTails}>
        <Layer {...selectedTailStyle} />
        <Layer {...selectedHiddenTailStyle} />
      </Source>
      {tooltipData && <ToolTipSelectionLayer {...tooltipData} />}
    </>
  );
};
