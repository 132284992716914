import { useCallback } from 'react';

import {
  FlightDataActionType,
  StreamedFlightPoint,
  useFlightDataContext,
} from 'src/@realtime/contexts/flight';
import { PlaybackActionType, usePlaybackContext } from 'src/@realtime/contexts/playback';
import { getOperationType } from 'src/@realtime/utils';
import { useMapConfigsWrapper } from '../map';
import { useConfigSelectors } from 'src/app/reducers';
import { getOperationTypeColor } from 'src/utils';
import { PLAYBACK_TICK_INTERVAL_MS } from 'src/@realtime/constants';

export const useLoadLiveFlightData = () => {
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');

  const { dispatch: flightDispatch } = useFlightDataContext();
  const { sitePrefix } = useMapConfigsWrapper();

  const { dispatch: playbackDispatch } = usePlaybackContext();

  const loadPointData = useCallback(
    (trackPoint: StreamedFlightPoint) => {
      const { time } = trackPoint;
      const newTimestamp =
        Math.round(new Date(time).getTime() / PLAYBACK_TICK_INTERVAL_MS) *
        PLAYBACK_TICK_INTERVAL_MS;

      playbackDispatch({
        type: PlaybackActionType.ADD_TIMESTAMP,
        payload: newTimestamp,
      });

      const operationType = getOperationType({
        destination: trackPoint.destination,
        origin: trackPoint.origin,
        siteCode: sitePrefix,
      });
      const operationColor = getOperationTypeColor(selectedTrackTheme, operationType);

      flightDispatch({
        type: FlightDataActionType.ADD_FLIGHT,
        payload: {
          point: trackPoint,
          operationType,
          operationColor,
        },
      });

      flightDispatch({
        type: FlightDataActionType.ADD_POSITION,
        payload: {
          time: newTimestamp,
          trackPoint,
        },
      });
    },
    [flightDispatch, playbackDispatch]
  );

  return loadPointData;
};
