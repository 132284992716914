import React, { useEffect, useState, useMemo } from 'react';
import { Source, Layer } from 'react-map-gl';
import { selectedAircraftCircleStyle } from './styles';
import { FeatureCollection } from 'geojson';
import { SelectionLayerIds } from './constants';

interface PulsingSelectionLayerProps {
  selectedTracks: string[];
  features: FeatureCollection;
}

export const PulsingSelectionLayer: React.FC<PulsingSelectionLayerProps> = ({
  selectedTracks,
  features,
}) => {
  const [pulsingRadius, setPulsingRadius] = useState(0);
  const [pulsingOpacity, setPulsingOpacity] = useState(0);

  useEffect(() => {
    if (selectedTracks.length < 1) return;

    let radius = 0;
    let opacity = 0.8;
    let phase = 'growing';

    const interval = setInterval(() => {
      if (phase === 'growing') {
        radius += 1.5; // Increment radius to reach 25 in 1 second
        opacity = 0.9 * (1 - radius / 25); // Decrease opacity as radius grows for pulse effect
        if (radius >= 25) {
          radius = 0; // Reset radius
          opacity = 0; // Ensure hidden for pulse effect
          phase = 'hidden';
          setTimeout(() => {
            phase = 'growing'; // Restart animation
          }, 1000);
        }
      }

      setPulsingRadius(radius);
      setPulsingOpacity(opacity);
    }, 100);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [selectedTracks]);

  const filter = useMemo(() => {
    return selectedTracks.length > 0
      ? ['in', 'trackId', ...selectedTracks]
      : ['in', 'trackId', ''];
  }, [selectedTracks]);

  return (
    <Source id={SelectionLayerIds.selectedAircraftCircle} type="geojson" data={features}>
      <Layer
        id={selectedAircraftCircleStyle.id}
        type={selectedAircraftCircleStyle.type}
        filter={filter}
        paint={{
          ...selectedAircraftCircleStyle.paint, // Paint the CSS Stlye
          'circle-radius': pulsingRadius,   // Dynamically update radius
          'circle-opacity': pulsingOpacity, // Dynamically update opacity
        }}
      />
    </Source>
  );
};
