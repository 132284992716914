import React from 'react';
import { useFlightDataContext } from 'src/@realtime/contexts';
import { useConfigSelectors } from 'src/app/reducers';
import { IMPERIAL_BASE_UNIT, METRIC_BASE_UNIT, UNIT_FOOT } from 'src/constants';
import { getVerticalDistance } from 'src/utils';
import { RealtimeMapTag } from '../realtimeMapTag';
import {
  FlightTagContentsWrapper,
  FlightTagContentsInfo,
  FlightTagContentsTailImg,
} from './styles';

interface ToolTipSelectionLayerProps {
  coordinates: GeoJSON.Position;
  altitude: number;
  trackId: string;
}

export const ToolTipSelectionLayer: React.FC<ToolTipSelectionLayerProps> = ({
  coordinates,
  altitude,
  trackId,
}) => {
  const arrowPosition = 'top-left';
  const units = useConfigSelectors().getUnits();
  const verticalDistanceUnit = units.distanceVertical;

  const {
    state: { flightInfo },
  } = useFlightDataContext();

  if (!flightInfo[trackId]) {
    return null;
  }

  const { acId, origin, destination, aircraftType } = flightInfo[trackId];

  const acAlt = getVerticalDistance(altitude, {
    convertTo: verticalDistanceUnit === UNIT_FOOT ? IMPERIAL_BASE_UNIT : METRIC_BASE_UNIT,
    convertFrom: 'ft',
    returnValueType: 'string',
  }) as string;

  return (
    <RealtimeMapTag longitude={coordinates[0]} latitude={coordinates[1]} anchor={arrowPosition}>
      <FlightTagContentsWrapper>
        <FlightTagContentsTailImg>
          <img width="35" src="/logos/QF.png" alt="Logo" />
        </FlightTagContentsTailImg>
        <FlightTagContentsInfo>
          <span className="acId" style={{ fontWeight: 'bold' }}>
            {acId || 'Unknown'}
          </span>
          {origin !== null && destination !== null && (
            <span className="oridest">
              {origin || 'N/A'} &#8594; {destination || 'N/A'}
            </span>
          )}
          {aircraftType !== null && <span className="aircraftType">{aircraftType}</span>}
          {acAlt && acAlt.length > 0 && <span className="altitude">{acAlt}</span>}
        </FlightTagContentsInfo>
      </FlightTagContentsWrapper>
    </RealtimeMapTag>
  );
};
