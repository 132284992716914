import { useMemo, useRef } from 'react';
import { point, featureCollection, FeatureCollection, Point } from '@turf/turf';
import { useNmtContext, usePlaybackContext } from 'src/@realtime/contexts';
import { NoiseMonitor } from 'src/@realtime/queries';
import { NmtLayerProperties } from './types';

export const useBuildActiveNmtOLD = (): FeatureCollection<Point> | null => {
  const {
    state: { monitors, hasLoaded },
  } = useNmtContext();

  return useMemo(() => {
    if (!hasLoaded || !monitors?.length) {
      return null;
    }

    // Filter active monitors with valid positions
    const activeMonitors = monitors.filter(feature => feature.isActive && feature.position);

    // Create point features from active monitors
    const features = activeMonitors.map(({ deviceId, position }) => {
      const pt = point([position.longitude, position.latitude], { deviceId, noiseLevel: 0 });
      pt.id = deviceId;
      return pt;
    });

    return featureCollection(features);
  }, [monitors, hasLoaded]);
};

export const useBuildActiveNmt = () => {
  const {
    state: { monitors, hasLoaded, samples },
  } = useNmtContext();
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();

  // Ref to store active monitors
  const activeMonitorsRef = useRef<NoiseMonitor[]>([]);

  // Update active monitors ref only when monitors change
  useMemo(() => {
    if (monitors.length) {
      activeMonitorsRef.current = monitors.filter(monitor => monitor.isActive);
    } else {
      activeMonitorsRef.current = [];
    }
  }, [monitors]);

  return useMemo(() => {
    if (!hasLoaded || !activeMonitorsRef.current.length || !samples) {
      return null;
    }

    // Helper to create a point feature
    const createPoint = (longitude: number, latitude: number, deviceId: number, noiseLevel = 0) =>
      point<NmtLayerProperties>([longitude, latitude], { deviceId, noiseLevel });

    const features = activeMonitorsRef.current.map(monitor => {
      const { deviceId, position } = monitor;

      // Ensure monitor has valid position
      if (!position || !position.longitude || !position.latitude) {
        return null;
      }

      const monitorSamples = samples[deviceId];
      if (!monitorSamples) {
        return createPoint(position.longitude, position.latitude, deviceId);
      }

      // Find matching sample
      const matchingSample = monitorSamples.find(
        ({ time }) => new Date(time).getTime() === currentTimestamp
      );

      return createPoint(
        position.longitude,
        position.latitude,
        deviceId,
        matchingSample?.value || 0
      );
    });

    return featureCollection(features.filter(Boolean));
  }, [hasLoaded, samples, currentTimestamp]);
};
