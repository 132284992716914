import React from 'react';
import { TimeDisplay } from '../timeDisplay';
import { ControlButtonsWrapper, ControlButton, ControlButtonContainer } from './styles';
import { usePlaybackContext } from 'src/@realtime/contexts';
import { useGoLive } from 'src/@realtime/hooks';

export const ControlButtons: React.FC = ({}) => {
  const goLive = useGoLive();
  const {
    state: { currentTimestamp, maxTimestamp, isLive },
  } = usePlaybackContext();

  return (
    <ControlButtonsWrapper>
      <TimeDisplay current={currentTimestamp} max={maxTimestamp} />
      <ControlButtonContainer>
        {/* Historical currently disabled */}
        {/* <ControlButton className="skip-back" size="small">
          <FaChevronLeft />
        </ControlButton>
        <ControlButton className="skip-forward" size="small">
          <FaChevronRight />
        </ControlButton>
        <ControlButton className="speed" size="large">
          90x
        </ControlButton> */}
        <ControlButton
          disabled={isLive}
          className="live"
          size="large"
          variation={isLive ? 'live' : undefined}
          onClick={goLive}>
          LIVE
        </ControlButton>
      </ControlButtonContainer>
    </ControlButtonsWrapper>
  );
};
