import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

export const CircleButton = styled.button`
  width: 32px;
  height: 32px;
  background: ${sassVars.background};
  border: 1px solid ${sassVars.ui05};
  color: ${sassVars.ui05};
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 2px solid ${sassVars.ui05};
  }
`;
