import { useEffect, useRef } from 'react';
import { useMap } from 'react-map-gl';
import { FeatureCollection, Point } from 'geojson';
import { useMapContext } from 'src/@realtime/contexts/map';
import * as turf from '@turf/turf';
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import mapboxgl from 'mapbox-gl';
import sassVars from 'src/styles/vars.module.scss';
import { calculateDistance } from 'src/@realtime/utils';

export const MeasureLayer = () => {
  const { current: mapRef } = useMap();
  const { state } = useMapContext();
  const startPointRef = useRef<[number, number] | null>(null);
  const endPointRef = useRef<[number, number] | null>(null);
  const configSelectors = useConfigSelectors();
  const selectedDistanceUnits = configSelectors.getDistanceUnitsProfile() as
    | 'US Customary'
    | 'ICAO Metric'
    | 'ICAO Alternative'
    | 'Local System';

  const updateMapFeatures = (map: mapboxgl.Map) => {
    if (!startPointRef.current || !endPointRef.current) return;

    const startPoint = startPointRef.current;
    const endPoint = endPointRef.current;
    const distance = calculateDistance(startPoint, endPoint, selectedDistanceUnits);

    // The dotted line
    const line = turf.lineString([startPoint, endPoint]);

    // The handle circles
    const features: FeatureCollection<Point> = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: startPoint },
          properties: { type: 'start' },
        },
        {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: endPoint },
          properties: {
            type: 'end',
            distance: distance,
          },
        },
      ],
    };

    (map.getSource('measure-line') as mapboxgl.GeoJSONSource)?.setData(line);
    (map.getSource('measure-points') as mapboxgl.GeoJSONSource)?.setData(features);

    const existingLabel = document.querySelector('.ruler-label-container');
    if (existingLabel) existingLabel.remove();

    // Add a custom HTML label near the end point, the MapBox  type: 'symbol' doesn't look good at all.
    const markerElement = createDistanceLabel(distance);
    new mapboxgl.Marker({
      element: markerElement,
      anchor: 'bottom', // Anchor at the bottom to align the circle
    })
      .setLngLat(endPoint)
      .addTo(map);
  };

  useEffect(() => {
    const map = mapRef?.getMap() as mapboxgl.Map;
    if (!map) return;

    const initializeMeasureLine = () => {
      const center = map.getCenter();
      // Initial start and end points 40 km apart, the old method was OTT, it offset by 100 and used projection to calculate an artifical distance
      const initialStart: [number, number] = [center.lng - 0.2051, center.lat];
      const initialEnd: [number, number] = [center.lng + 0.2, center.lat];
      startPointRef.current = initialStart;
      endPointRef.current = initialEnd;

      if (!map.getSource('measure-line')) {
        map.addSource('measure-line', { type: 'geojson', data: turf.lineString([[0, 0], [0, 0]]) });
        map.addLayer({
          id: 'measure-line-layer',
          type: 'line',
          source: 'measure-line',
          paint: {
            'line-color': sassVars.brand01,
            'line-width': 2,
            'line-dasharray': [2, 1],
          },
        });
      }

      if (!map.getSource('measure-points')) {
        map.addSource('measure-points', {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [] },
        });
        map.addLayer({
          id: 'measure-points-layer',
          type: 'circle',
          source: 'measure-points',
          paint: {
            'circle-radius': 4,                     // Circle size
            'circle-color': sassVars.white,         // White inside
            'circle-stroke-width': 2,               // Stroke thickness
            'circle-stroke-color': sassVars.brand01, // Blue outline
          },
        });
      }

      updateMapFeatures(map);
    };

    const handleCircleDrag = (e: mapboxgl.MapMouseEvent, type: 'start' | 'end') => {
      const lngLat: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      if (type === 'start') startPointRef.current = lngLat;
      if (type === 'end') endPointRef.current = lngLat;
      updateMapFeatures(map);
    };

    if (state.isMeasuring) {
      initializeMeasureLine();

      map.on('mousedown', (e) => {
        const features = map.queryRenderedFeatures(e.point, { layers: ['measure-points-layer'] });
        if (!features.length) return;

        const feature = features[0];
        const type = feature.properties?.type;

        // Disable map dragging during handle drag
        map.dragPan.disable();

        const handleMouseMove = (moveEvent: mapboxgl.MapMouseEvent) => {
          if (type === 'start' || type === 'end') {
            handleCircleDrag(moveEvent, type);
          }
        };

        map.on('mousemove', handleMouseMove);

        map.once('mouseup', () => {
          map.off('mousemove', handleMouseMove);
          map.dragPan.enable(); // Re-enable map dragging after the handle is released
        });
      });

    } else {
      // Cleanup when exiting measurement mode
      const existingLabel = document.querySelector('.ruler-label-container');
      if (existingLabel) existingLabel.remove();

      startPointRef.current = null;
      endPointRef.current = null;

      if (map.getLayer('measure-line-layer')) {
        map.removeLayer('measure-line-layer');
        map.removeSource('measure-line');
      }
      if (map.getLayer('measure-points-layer')) {
        map.removeLayer('measure-points-layer');
        map.removeSource('measure-points');
      }
    }

    return () => {
      map.off('mousedown', null);
    };
  }, [state.isMeasuring]);

  return null;
};

const createDistanceLabel = (distance: string) => {
  // Create the outer container
  const containerDiv = document.createElement('div');
  containerDiv.className = 'ruler-end-container';
  containerDiv.style.left = '10px'; // Offset from the end point
  containerDiv.style.whiteSpace = 'nowrap';

  // Create the label
  const labelDiv = document.createElement('div');
  labelDiv.className = 'ruler-label-container';
  labelDiv.textContent = distance;

  // Add the label to the container
  containerDiv.appendChild(labelDiv);

  return containerDiv;
};
