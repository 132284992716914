import React, { useEffect, useState } from 'react';
import { Layer, useMap } from 'react-map-gl';
import { NmtLayerIds } from 'src/@realtime/components/nmtLayer/constants';
import { FlightTailLayerIds } from '../flightTailLayer/constants';
import { FlightLayerIds } from '../flightLayer';

export const ShadedMapLayer: React.FC = () => {
  const { current: map } = useMap();
  const [beforeId, setBeforeId] = useState<string | null>(null);

  useEffect(() => {
    if (!map) {
      return;
    }

    const findLowestLayer = () => {
      const layerIds = [
        ...Object.values(NmtLayerIds),
        ...Object.values(FlightLayerIds),
        ...Object.values(FlightTailLayerIds),
      ];

      // Find the first existing layer from the layerIds list
      for (const id of layerIds) {
        if (map.getLayer(id)) {
          setBeforeId(id);
          return;
        }
      }

      // Retry if no layer is found
      setTimeout(findLowestLayer, 100);
    };

    findLowestLayer();
  }, [map]);

  if (!beforeId) {
    return null;
  }

  return (
    <Layer
      id="dark-grey-overlay"
      type="background"
      paint={{
        'background-color': 'rgba(0, 0, 0, .52)',
      }}
      beforeId={beforeId}
    />
  );
};
