import React, { useMemo } from 'react';
import { Source, Layer } from 'react-map-gl';
import { FeatureCollection } from 'geojson';
import { SHADOW_ICON_OFFSETS } from 'src/@realtime/constants';
import { flightLayerStyle, shadowLayerStyle } from './styles';
import { useAddMapEventFunction } from 'src/@realtime/hooks';
import { loadMapSprites, queryMapFeaturesById } from 'src/@realtime/utils';
import { PulsingSelectionLayer } from '../flightSelectionLayer/pulsingSelectionLayer';
import { FlightLayerIds } from './constants';
import { FlightDataActionType, useFlightDataContext } from 'src/@realtime/contexts';
import { Feature } from 'geojson';
import { SpriteKeys } from 'src/@realtime/utils/sprites';
import { useConfigSelectors } from 'src/app/reducers';
import { MapEventTypes } from 'src/@realtime/contexts/map';
import { useBuildFlightData } from './hooks';

const generateFeatures = (count: number): Feature[] => {
  const features: Feature[] = [];
  Object.keys(SpriteKeys).forEach((key, index) => {
    for (let i = 0; i < count; i++) {
      features.push({
        id: `${key}-${i}`,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [152.9 + index * 0.02, -28.1 + i * 0.02],
        },
        properties: {
          rotation: i * 15,
          operationType: 'd',
          iconKey: SpriteKeys[key as keyof typeof SpriteKeys],
          shadowOffset: (SHADOW_ICON_OFFSETS[i * 15] as [number, number]) || [0, 0],
        },
      });
    }
  });
  return features;
};

const geojson: FeatureCollection = {
  type: 'FeatureCollection',
  features: generateFeatures(25),
};

export const FlightLayer = ({ debug = false }: { debug?: boolean }): JSX.Element => {
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');

  const {
    state: { selectedTracks },
    dispatch: flightDispatch,
  } = useFlightDataContext();

  const flightData = useBuildFlightData();

  const clickFunction = useMemo(
    () => ({
      id: 'my-map-click',
      type: MapEventTypes.CLICK,
      event: (event: mapboxgl.MapLayerMouseEvent) => {
        const { target, point } = event;
        const features = queryMapFeaturesById(target, point, FlightLayerIds.flightLayer);
        if (features[0]) {
          console.log('Selected track:', features[0].properties);
          flightDispatch({
            type: FlightDataActionType.SELECT_TRACK,
            payload: features[0].properties?.trackId as string,
          });
        }
      },
    }),
    []
  );

  useAddMapEventFunction(clickFunction);

  // Collect the selected flights info required for Selections
  const selectedFeatures = useMemo(
    () =>
      flightData.features.filter(feature => selectedTracks.includes(feature.properties?.trackId)),
    [flightData, selectedTracks]
  );

  loadMapSprites(selectedTrackTheme);

  return (
    <>
      <Source id={FlightLayerIds.flightLayer} type="geojson" data={debug ? geojson : flightData}>
        <Layer {...shadowLayerStyle} />
        <Layer {...flightLayerStyle} />
      </Source>

      <PulsingSelectionLayer
        selectedTracks={selectedTracks}
        features={{
          type: 'FeatureCollection',
          features: selectedFeatures, // To know the position of the AC the user selected we pass the selectedFeatures containing coordinates.
        }}
      />
    </>
  );
};
