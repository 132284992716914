import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

export const ControlButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: bottom;
`;

export const ControlButton = styled.button<{ size: 'small' | 'large'; variation?: 'live' }>`
  width: ${props => (props.size === 'large' ? '28px' : '14px')};
  height: 14px;
  margin: 0;
  padding: 0;
  background: ${props => (props.variation === 'live' ? sassVars.support01 : 'transparent')};
  border: ${props => (props.variation === 'live' ? 'none' : `1px solid ${sassVars.ui05}`)};
  font-size: 10px;
  border-radius: 20px;
  color: ${props => (props.variation === 'live' ? 'white' : sassVars.ui04)};
  text-transform: ${props => (props.variation === 'live' ? 'uppercase' : 'none')};

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background: ${props => (props.variation === 'live' ? sassVars.support01 : sassVars.ui04)};
    color: white;
    fill: white;
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ControlButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;

    &.live {
      background: ${sassVars.support01};
    }
  }

  @media (max-width: 600px) {
    margin-right: 0px;
    justify-content: flex-end;
  }
`;
