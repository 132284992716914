import React from 'react';
import { useFlightDataContext, usePlaybackContext } from 'src/@realtime/contexts';

export const DebugPanel = () => {
  const {
    state: { flightInfo },
  } = useFlightDataContext();
  const {
    state: {
      timestamps,
      playbackStatus,
      currentTimestamp,
      minTimestamp,
      maxTimestamp,
      maxPlayableTimestamp,
    },
  } = usePlaybackContext();

  return (
    <div
      style={{
        backgroundColor: 'white',
        float: 'left',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        padding: '10px',
      }}>
      <p>Number of flights loaded: {Object.keys(flightInfo).length}</p>
      <p>Playback Status: {playbackStatus}</p>
      <p>Current Timestamp: {new Date(currentTimestamp).toLocaleString()}</p>
      <p>Min Time: {new Date(minTimestamp).toLocaleString()}</p>
      <p>Max Time: {new Date(maxTimestamp).toLocaleString()}</p>
      <p>Max Playable Time: {new Date(maxPlayableTimestamp).toLocaleString()}</p>
      <p>
        First Timestamp: {timestamps.length > 0 ? new Date(timestamps[0]).toLocaleString() : 'N/A'}
      </p>
      <p>
        Last Timestamp:
        {timestamps.length > 0
          ? new Date(timestamps[timestamps.length - 1]).toLocaleString()
          : 'N/A'}
      </p>
      <p>
        Time range:
        {timestamps.length > 0
          ? `${((timestamps[timestamps.length - 1] - timestamps[0]) / 1000).toFixed(2)} seconds`
          : 'N/A'}
      </p>
    </div>
  );
};
