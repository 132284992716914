import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import { nmtReducer } from './reducer';
import { NmtState, NmtAction, NmtActionType } from './types';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_NOISE_MONITOR_LOCATIONS,
  GetNoiseMonitorLocationsResponse,
} from 'src/@realtime/queries';

// Initial state
export const initialState: NmtState = {
  hasLoaded: false,
  monitors: [],
  samples: {},
};

// Context and dispatch setup
const NmtContext = createContext<{
  state: NmtState;
  dispatch: Dispatch<NmtAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const NmtProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(nmtReducer, initialState);

  const { loading, data } = useQuery<GetNoiseMonitorLocationsResponse>(GET_NOISE_MONITOR_LOCATIONS);

  if (!state.hasLoaded && !loading && data) {
    dispatch({ type: NmtActionType.ADD_MONITORS, payload: data.monitorLocationSummaries.items });
  }

  return <NmtContext.Provider value={{ state, dispatch }}>{children}</NmtContext.Provider>;
};

// Custom hook to access playback state
export const useNmtContext = () => {
  const context = useContext(NmtContext);
  if (!context) {
    throw new Error('useNmtContext must be used within a MapContext');
  }
  return context;
};
