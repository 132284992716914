import React from 'react';

import { CircleButton } from './styles';
import { Spinner } from '@ems/client-design-system';
import { FaPlay, FaPause } from 'react-icons/fa';
import { usePlaybackContext, PlaybackStatus, PlaybackActionType } from 'src/@realtime/contexts';

export const PlaybackButton: React.FC = ({}) => {
  const {
    state: { playbackStatus },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  const handlePlaybackToggle = () => {
    if (playbackStatus === PlaybackStatus.Buffering) {
      return;
    }
    playbackDispatch({
      type: PlaybackActionType.SET_PLAYBACK_STATUS,
      payload:
        playbackStatus === PlaybackStatus.Playing ? PlaybackStatus.Paused : PlaybackStatus.Playing,
    });
  };
  return (
    <CircleButton onClick={handlePlaybackToggle}>
      {playbackStatus === PlaybackStatus.Buffering ? (
        <Spinner loading size="s" />
      ) : playbackStatus === PlaybackStatus.Playing ? (
        <FaPause />
      ) : (
        <FaPlay />
      )}
    </CircleButton>
  );
};
