import styled from 'styled-components';
import { Popup, PopupProps } from 'react-map-gl';

export const FlightPopup = styled(Popup)<PopupProps>`
  .mapboxgl-popup-content {
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.8) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    z-index: 1;
    color: #fff;
    padding: 5px;
  }

  /* Bottom-right arrow */
  &.tooltip-bottom-right .mapboxgl-popup-content::after {
    bottom: -10px; /* Position below the tooltip */
    right: 10px; /* Align to the right */
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }

  /* Bottom-left arrow */
  &.tooltip-bottom-left .mapboxgl-popup-content::after {
    bottom: -10px;
    left: 10px;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }

  /* Top-right arrow */
  &.tooltip-top-right .mapboxgl-popup-content::after {
    top: -10px; /* Position above the tooltip */
    right: 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  }

  /* Top-left arrow */
  &.tooltip-top-left .mapboxgl-popup-content::after {
    top: -10px;
    left: 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  }

  .mapboxgl-popup-tip {
    display: none; /* Hides the default arrow */
  }

  .mapboxgl-popup-close-button {
    display: none; /* Hides the default arrow */
  }

  .tooltip {
    position: absolute;
    transform: translate(-50%, -50%); /* Center if necessary */
    pointer-events: none; /* Avoid interfering with mouse events */
  }

  .tooltip-table {
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25;
    padding: 0.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    width: auto;
    margin-bottom: -2px;
  }

  .tooltip-table table {
    width: 100%;
    border-collapse: collapse; /* Remove gaps between table cells */
  }

  .tooltip-table td {
    vertical-align: top; /* Align content to the top */
    padding: 5px; /* Add spacing inside cells */
  }

  .tooltip-table .left-square {
    width: 40px; /* Fixed width for the image column */
    height: 40px; /* Fixed height for square appearance */
    text-align: center; /* Center-align image */
  }

  .tooltip-table .right-content {
    width: auto; /* Take remaining space */
  }

  .tooltip-table .row {
    margin-bottom: 2px; /* Space between rows */
    display: flex; /* Align label and value */
    justify-content: space-between;
  }

  .tooltip-table .value {
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
  }
`;
