import React from 'react';
import { StyledTimeDisplay } from './styles';

interface TimeDisplayProps {
  current: number;
  max: number;
}

export const TimeDisplay: React.FC<TimeDisplayProps> = ({ current, max }) => (
  <StyledTimeDisplay>
    <span className="current-time">{new Date(current || 0).toLocaleTimeString(undefined)}</span>/
    <span className="max-time">{new Date(max || 0).toLocaleTimeString(undefined)}</span>
  </StyledTimeDisplay>
);
