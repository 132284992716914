import { useEffect, useRef } from 'react';
import {
  NmtActionType,
  StreamedFlightPoint,
  useAuthStoreContext,
  useNmtContext,
} from 'src/@realtime/contexts';
import {
  useLoadLiveFlightData,
  useSignalrConnection,
  useSignalrSubscription,
} from 'src/@realtime/hooks';
import {
  PLAYBACK_MAX_HISTORY,
  STREAM_HUB_NAMES,
  STREAM_METHOD_NAMES,
} from 'src/@realtime/constants';
import { getDeployedProductId } from 'src/utils';
import {
  StreamedSubscriptionParameters,
  StreamedFlightPoints,
  StreamedNoiseSamples,
} from 'src/@realtime/types';
import { useConfigSelectors } from 'src/app/reducers';
import { unstable_batchedUpdates } from 'react-dom';

const useBatchUpdates = () => {
  const queue = useRef<Record<string, StreamedFlightPoint[]>>({});
  const loadPointData = useLoadLiveFlightData();
  const dispatchInterval = useRef(0);

  useEffect(() => {
    dispatchInterval.current = window.setInterval(() => {
      if (!Object.keys(queue.current).length) {
        return;
      }
      unstable_batchedUpdates(() => {
        Object.entries(queue.current).forEach(([key, value]) => {
          value.forEach(value => {
            loadPointData(value);
          });
        });
      });
    }, 1000);
    return () => {
      window.clearInterval(dispatchInterval.current);
    };
  }, []);

  return queue;
};

export const useLoadLiveData = () => {
  const q2 = useBatchUpdates();
  const loadPointData = useLoadLiveFlightData();
  const { token } = useAuthStoreContext();
  const {
    AviationRealtimeApi: { EndpointUrl },
  } = useConfigSelectors().getConfig();

  const { dispatch: nmtDispatch } = useNmtContext();

  // Flight connection
  const { connection: flightConnection } = useSignalrConnection(
    `${EndpointUrl}${getDeployedProductId()}/${STREAM_HUB_NAMES.REAL_TIME_FLIGHT}`,
    token
  );

  // Only pass `connection` if it exists to avoid calling the hook unnecessarily.
  useSignalrSubscription<StreamedFlightPoints, StreamedSubscriptionParameters>({
    connection: flightConnection || null, // Provide `null` or a fallback if `connection` is not ready
    subscriptionParameters: flightConnection
      ? [
          {
            streamParameters: [
              STREAM_METHOD_NAMES.GET_TRACK_POINTS,
              { historySeconds: PLAYBACK_MAX_HISTORY },
            ],
            subscriber: {
              next: (data: StreamedFlightPoints) => {
                const { trackId } = data.point as StreamedFlightPoint;
                loadPointData(data.point);
                if (!q2.current[trackId]) {
                  q2.current[trackId] = [data.point];
                } else {
                  q2.current[trackId].push(data.point);
                }
              },
              complete: () => {},
              error: e => {
                console.error(e);
              },
            },
          },
        ]
      : [],
  });

  // Noise connection
  const { connection: noiseConnection } = useSignalrConnection(
    `${EndpointUrl}${getDeployedProductId()}/${STREAM_HUB_NAMES.REAL_TIME_NOISE}`,
    token
  );

  useSignalrSubscription<StreamedNoiseSamples, StreamedSubscriptionParameters>({
    connection: noiseConnection || null, // Provide `null` or a fallback if `connection` is not ready
    subscriptionParameters: noiseConnection
      ? [
          {
            streamParameters: [
              STREAM_METHOD_NAMES.GET_NOISE_SAMPLES,
              { historySeconds: PLAYBACK_MAX_HISTORY },
            ],
            subscriber: {
              next: (data: StreamedNoiseSamples) => {
                nmtDispatch({
                  type: NmtActionType.ADD_NOISE_SAMPLE,
                  payload: [data.sample],
                });
              },
              complete: () => {},
              error: e => {
                console.error(e);
              },
            },
          },
        ]
      : [],
  });
};
