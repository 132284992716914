import { useMemo } from 'react';
import { useFlightDataContext, usePlaybackContext } from 'src/@realtime/contexts';
import { point, featureCollection, Feature, Point } from '@turf/turf';
import { SpriteKeys } from 'src/@realtime/utils/sprites';
import { SHADOW_ICON_OFFSETS } from 'src/@realtime/constants';

interface FlightProperties {
  trackId: string;
  iconKey: SpriteKeys;
  shadowOffset: [number, number];
  heading: number;
  operationType: string;
  altitude: number;
}

export const useBuildFlightData = () => {
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();
  const {
    state: { positions, flightInfo },
  } = useFlightDataContext();

  return useMemo(() => {
    const features: Feature<Point, FlightProperties>[] = [];
    for (const [trackId, trackPositions] of Object.entries(positions)) {
      if (trackPositions.length < 5) {
        continue;
      }

      const pos = trackPositions.find(p => p.time === currentTimestamp);
      if (!pos) {
        continue;
      }

      const { longitude, latitude, altitude, heading } = pos;
      const { operationType, aircraftType } = flightInfo[trackId];
      const roundedHeading = Math.round(heading / 15) * 15;
      features.push(
        point<FlightProperties>(
          [longitude, latitude, altitude],
          {
            trackId,
            iconKey: aircraftType as SpriteKeys,
            shadowOffset: SHADOW_ICON_OFFSETS[roundedHeading] || [0, 0],
            heading,
            operationType,
            altitude,
          },
          { id: trackId }
        )
      );
    }

    return featureCollection(features);
  }, [currentTimestamp, positions, flightInfo]);
};
