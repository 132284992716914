import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

import { SliderStyles } from 'rc-slider/lib/interface';

export const sliderStyles: SliderStyles = {
  track: {
    background: sassVars.text02,
    height: 6,
  },
  handle: {
    border: '2px solid white',
    height: 16,
    width: 16,
    backgroundColor: sassVars.brand01,
    marginTop: -5,
    opacity: 1,
  },
  rail: {
    background: sassVars.ui03,
    height: 6,
  },
};

export const StyledSliderTooltip = styled.div`
  position: absolute;
  z-index: 2; /* Position in front the thumb */
  bottom: 20px; /* Position above the thumb */
  transform: translateX(-50%);
  background: ${sassVars.text01};
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none; /* Ensure tooltip doesn't block interactions */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.4s ease-in-out;

  &.visible {
    opacity: 1; /* Show tooltip when .visible class is added */
  }

  /* The arrow to make the tooltip look like a Speach Bubble  */
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px; /* Creates a downward triangle */
    border-color: ${sassVars.text01} transparent transparent transparent;
  }
`;
