import React from 'react';
import { PlaybackPanelWrapper, PlaybackPanel } from './style';
import { ControlButtons, PlaybackButton, Slider } from './components';

export const PlaybackControl = () => (
  <PlaybackPanelWrapper>
    <PlaybackPanel>
      <PlaybackButton />
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: '0 8px',
        }}>
        <Slider />
        <ControlButtons />
      </div>
      {/* Historical currently disabled */}
      {/* <CalendarButton /> */}
    </PlaybackPanel>
  </PlaybackPanelWrapper>
);
