import {
  PLAYBACK_BUFFER_SIZE,
  PLAYBACK_HISTORY_MILLISECONDS,
  PLAYBACK_TICK_INTERVAL_MS,
} from 'src/@realtime/constants';
import { PlaybackAction, PlaybackActionType, PlaybackState, PlaybackStatus } from './types';
import { interpolateTimestamps } from 'src/@realtime/utils';

export const playbackReducer = (state: PlaybackState, action: PlaybackAction): PlaybackState => {
  switch (action.type) {
    case PlaybackActionType.ADD_TIMESTAMP:
      const newTimestamp = action.payload;

      // No timestamps yet, just add
      if (state.timestamps.length === 0) {
        return {
          ...state,
          timestamps: [newTimestamp],
        };
      }

      // Skip if timestamp already exists
      if (state.timestamps.includes(newTimestamp)) {
        return state;
      }

      if (state.timestamps.length === 1) {
        const timestamps = interpolateTimestamps(state.timestamps[0], newTimestamp);
        return {
          ...state,
          timestamps: [...state.timestamps, ...timestamps],
        };
      }

      if (state.timestamps.length === 1) {
        const timestamps = interpolateTimestamps(state.timestamps[0], newTimestamp);
        return {
          ...state,
          timestamps: [...state.timestamps, ...timestamps],
        };
      }

      // Find the closest timestamp and fill in the gaps
      const closest = state.timestamps.reduce(
        (prev, curr) =>
          Math.abs(curr - newTimestamp) < Math.abs(prev - newTimestamp) ? curr : prev,
        state.timestamps[0]
      );

      let updatedTimestamps: number[];
      if (closest) {
        const missingTimestamps: number[] = [];
        for (
          let ts = closest + PLAYBACK_TICK_INTERVAL_MS;
          ts < newTimestamp;
          ts += PLAYBACK_TICK_INTERVAL_MS
        ) {
          missingTimestamps.push(ts);
        }

        updatedTimestamps = [...state.timestamps, ...missingTimestamps, newTimestamp];
      } else {
        updatedTimestamps = [...state.timestamps, newTimestamp];
      }

      updatedTimestamps.sort((a, b) => a - b);

      // Determine buffering state
      const hasData = updatedTimestamps.length > 0;
      const isAtEndOfData =
        state.currentTimestamp !== null &&
        state.currentTimestamp >= updatedTimestamps[updatedTimestamps.length - 1];

      // If there is no data or the current timestamp is at the end of the data, set the playback status to Buffering
      const newPlaybackStatus =
        !hasData || isAtEndOfData ? PlaybackStatus.Buffering : state.playbackStatus;

      const minTimestamp = Math.min(...updatedTimestamps) + PLAYBACK_BUFFER_SIZE;
      const maxPlayableTimestamp = Math.max(...updatedTimestamps) - PLAYBACK_BUFFER_SIZE;
      const maxTimestamp =
        minTimestamp + PLAYBACK_HISTORY_MILLISECONDS > maxPlayableTimestamp
          ? minTimestamp + PLAYBACK_HISTORY_MILLISECONDS
          : maxPlayableTimestamp;

      return {
        ...state,
        timestamps: updatedTimestamps,
        currentTimestamp: state.currentTimestamp,
        playbackStatus: newPlaybackStatus,
        minTimestamp,
        maxPlayableTimestamp,
        maxTimestamp,
      };

    case PlaybackActionType.SET_PLAYBACK_STATUS:
      return {
        ...state,
        playbackStatus: action.payload,
      };

    case PlaybackActionType.SET_CURRENT_TIMESTAMP:
      const newCurrentTimestamp = action.payload;

      // Check if the new timestamp is beyond available data, as the user could scrub the timeline into the future.
      const isBeyondData =
        state.timestamps.length > 0 &&
        newCurrentTimestamp > state.timestamps[state.timestamps.length - 1];

      return {
        ...state,
        currentTimestamp: newCurrentTimestamp,
        playbackStatus: isBeyondData ? PlaybackStatus.Buffering : state.playbackStatus,
      };

    case PlaybackActionType.SET_IS_LIVE:
      return {
        ...state,
        isLive: action.payload,
      };
    case PlaybackActionType.ADVANCE_TIMESTAMP:
      const currentIndex =
        state.currentTimestamp !== null ? state.timestamps.indexOf(state.currentTimestamp) : -1;

      // Stay behind the latest data to avoid running out
      const lastTimestamp =
        state.timestamps.length > 0 ? state.timestamps[state.timestamps.length - 1] : null;

      if (state.isLive) {
        if (lastTimestamp !== null) {
          // Move currentTimestamp closer to the latest data while maintaining a 10-second buffer
          const targetTimestamp = Math.min(
            lastTimestamp - PLAYBACK_BUFFER_SIZE, // Stay behind with some buffer
            state.currentTimestamp + PLAYBACK_TICK_INTERVAL_MS // Increment normally
          );

          if (targetTimestamp > state.currentTimestamp) {
            return {
              ...state,
              currentTimestamp: targetTimestamp,
            };
          }
        }
      }

      // Stay a bit behind the last timestamp to avoid running out of data
      if (currentIndex < state.timestamps.length - 10) {
        const nextTimestamp = state.timestamps[currentIndex + 1];
        return {
          ...state,
          currentTimestamp: nextTimestamp,
        };
      } else {
        return {
          ...state,
          playbackStatus: PlaybackStatus.Buffering,
        };
      }

    default:
      return state;
  }
};
