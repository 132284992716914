import React, { PropsWithChildren } from 'react';
import { FlightDataProvider, PlaybackProvider, NmtProvider } from 'src/@realtime/contexts';
import { Provider as AnomsMapProvider } from 'src/components/Map/context/Provider';
import { MapProvider } from 'src/@realtime/contexts/map';

export const RealtimeProviders: React.FC<PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => (
  <AnomsMapProvider>
    <NmtProvider>
      <MapProvider>
        <FlightDataProvider>
          <PlaybackProvider>{children}</PlaybackProvider>
        </FlightDataProvider>
      </MapProvider>
    </NmtProvider>
  </AnomsMapProvider>
);
