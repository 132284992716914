import { useMemo } from 'react';
import { useFlightDataContext, usePlaybackContext } from 'src/@realtime/contexts';
import { FlightLayerIds } from 'src/@realtime/components';
import { Feature, FeatureCollection } from 'geojson';
import { useMap } from 'react-map-gl';
import { lineString } from '@turf/turf';
import { TailLineStringProperties } from 'src/@realtime/types/flight';

export const useBuildFlightTails = () => {
  const {
    state: { positions, flightInfo, selectedTracks },
  } = useFlightDataContext();

  const {
    state: { currentTimestamp },
  } = usePlaybackContext();

  const { current: map } = useMap();

  return useMemo(() => {
    if (!map) {
      return null;
    }

    const flightFeatures = map.querySourceFeatures(FlightLayerIds.flightLayer);
    if (!flightFeatures) {
      return null;
    }

    // Extract feature IDs from the source for quick lookup
    const existingFeatureIds = new Set(
      flightFeatures.map(feature => {
        const { properties } = feature;
        return properties?.trackId as string;
      })
    );

    const selectedFeatureList: Feature[] = [];
    const defaultFeatureList: Feature[] = [];

    Object.entries(positions).forEach(([trackId, trackPositions]) => {
      if (!existingFeatureIds.has(trackId)) {
        return;
      }

      const isSelected = selectedTracks.includes(trackId);
      const filteredPositions = isSelected
        ? trackPositions
        : trackPositions.filter(position => position.time < currentTimestamp).slice(-60);

      const coordinates = filteredPositions.map(position => [
        position.longitude,
        position.latitude,
        position.altitude,
      ]);
      const altitudes = filteredPositions.map(position => position.altitude);

      if (coordinates.length === 0 || coordinates.length < 2) {
        return;
      }
      const lineGeoJSON = lineString<TailLineStringProperties>(
        coordinates,
        {
          // Mapbox turns arrays into strings, is actually number[], turn into string for type safety
          altitudes: JSON.stringify(altitudes),
          isSelected,
          trackId,
          acId: flightInfo[trackId]?.acId,
          aircraftType: flightInfo[trackId]?.aircraftType,
          operationColor: flightInfo[trackId]?.operationColor,
          operationType: flightInfo[trackId]?.operationType,
          origin: flightInfo[trackId]?.origin,
        },
        { id: trackId }
      );

      if (isSelected) {
        selectedFeatureList.push(lineGeoJSON);
      } else {
        defaultFeatureList.push(lineGeoJSON);
      }
    });

    const flightTails: FeatureCollection = {
      type: 'FeatureCollection',
      features: defaultFeatureList,
    };
    const selectedFlightTails: FeatureCollection = {
      type: 'FeatureCollection',
      features: selectedFeatureList,
    };

    return [flightTails, selectedFlightTails];
  }, [map, positions, flightInfo, selectedTracks, currentTimestamp]);
};
