import GLMap, { MapProps as GLMapProps } from 'react-map-gl';
import React, { useCallback } from 'react';
import { MapEventTypes, useMapContext } from 'src/@realtime/contexts/map';
import { useDebounceFunction } from 'src/@realtime/hooks';

interface MapProps extends Omit<GLMapProps, 'mapboxAccessToken' | 'initialViewState' | 'mapStyle'> {
  latitude: number;
  longitude: number;
  zoom: number;
  apiKey: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Map: React.FC<MapProps> = ({
  latitude,
  longitude,
  zoom,
  apiKey,
  style,
  children,
  ...rest
}) => {
  const {
    state: { selectedMapStyle, mapEvents },
  } = useMapContext();

  const triggerMapEvents = useCallback(
    (e: mapboxgl.MapLayerMouseEvent, eventType: MapEventTypes) => {
      if (!mapEvents.length) {
        return;
      }
      mapEvents.filter(({ type }) => type === eventType).forEach(({ event }) => event(e));
    },
    [mapEvents]
  );

  const handleMapClick = useCallback(
    (e: mapboxgl.MapLayerMouseEvent) => {
      triggerMapEvents(e, MapEventTypes.CLICK);
    },
    [triggerMapEvents]
  );

  // Debounce the hover event to avoid triggering it too often
  const debouncedHoverHandler = useDebounceFunction((e: mapboxgl.MapLayerMouseEvent) => {
    triggerMapEvents(e, MapEventTypes.HOVER);
  }, 50);

  const handleMapHover = useCallback(
    (e: mapboxgl.MapLayerMouseEvent) => {
      debouncedHoverHandler(e);
    },
    [debouncedHoverHandler]
  );

  return (
    <GLMap
      onClick={e => handleMapClick(e as mapboxgl.MapLayerMouseEvent)}
      onMouseMove={e => handleMapHover(e as mapboxgl.MapLayerMouseEvent)}
      mapboxAccessToken={apiKey}
      initialViewState={{
        longitude,
        latitude,
        zoom,
      }}
      style={style}
      mapStyle={selectedMapStyle.url}
      {...rest}>
      {children}
    </GLMap>
  );
};
