// Apollo
import gql from 'graphql-tag';

export const GET_NOISE_MONITOR_LOCATIONS = gql`
  query getMonitorLocations {
    monitorLocationSummaries {
      totalCount
      items {
        locationId
        deviceId
        deviceName
        deviceDescription
        locationName
        locationDescription
        position {
          latitude
          longitude
          altitude
        }
        deviceType
        isActive
        lastData
      }
    }
  }
`;
