import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

export const FlexContainer = styled.div`
  display: flex;
`;

export const PlaybackPanelWrapper = styled.div`
  display: flex;
  z-index: 0;  /* Tooltips go over the playback control */
  bottom: 25px;
  left: 0;
  width: 100%;
  height: 72px;
  position: absolute;
  justify-content: center;
  pointer-events: none;  /* Allow mouse events to pass through */
   & * {
    pointer-events: auto; /* Enable pointer events for children */
  }
`;

export const PlaybackPanel = styled.div`
  --control-max-width: 800px; /* Define the width */

  position: relative;
  width: clamp(250px, 80%, var(--control-max-width)); /* Dynamically adjust controls width based on page size */
  max-width: var(--control-max-width)
  min-width: 410px !important;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${sassVars.background};
  border-radius: 35px;
  padding: 20px;
  z-index: -1;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2),inset 0px 2px 4px rgba(255, 255, 255, 0.1); /* Outer shadow */
 
  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    border: 1px solid ${sassVars.ui04};
    border-radius: calc(35px - 5px);
    pointer-events: none;
  }


  @media (max-width: 600px) {
    flex-wrap: wrap;
    height: 72px;
  }

`;
