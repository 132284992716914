import React, { useEffect } from 'react';
import { useMapConfigsWrapper, useLoadLiveData } from 'src/@realtime/hooks';
import {
  DebugPanel,
  PlaybackControl,
  FlightLayer,
  Map,
  MapControls,
  ShadedMapLayer,
} from 'src/@realtime/components';
import { useGoLive, useTimeProgression } from 'src/@realtime/hooks/playback';
import { PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';
import { calculateMaxBounds } from 'src/@realtime/utils';
import { DEFAULT_BBOX_DISTANCE } from 'src/constants';
import { FlightTailLayer } from 'src/@realtime/components/flightTailLayer';
import { NmtLayer } from 'src/@realtime/components/nmtLayer';

export const RealtimePage: React.FC = () => {
  const {
    centre: { latitude, longitude },
    zoom,
    mapAccessToken,
    maxZoom,
    minZoom,
  } = useMapConfigsWrapper();
  const {
    state: { timestamps, playbackStatus },
    dispatch: playbackDispatch,
  } = usePlaybackContext();
  const goLive = useGoLive();

  useLoadLiveData();
  useTimeProgression();

  // Start playback if we have enough data
  useEffect(() => {
    if (timestamps.length > 40 && playbackStatus === PlaybackStatus.Buffering) {
      goLive();
    }
  }, [timestamps, playbackDispatch]);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Map
        pitch={0}
        bearing={0}
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        apiKey={mapAccessToken}
        maxZoom={maxZoom}
        minZoom={minZoom}
        maxBounds={calculateMaxBounds(latitude, longitude, DEFAULT_BBOX_DISTANCE)}>
        <MapControls resetView mapSettings />
        <DebugPanel />
        <NmtLayer />
        <PlaybackControl />
        <FlightTailLayer />
        <FlightLayer />
        <ShadedMapLayer />
      </Map>
    </div>
  );
};
