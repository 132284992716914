import { useEffect, useRef } from 'react';
import { MapActionType, MapEventConfig, useMapContext } from 'src/@realtime/contexts/map';

export const useAddMapEventFunction = (eventFunction: MapEventConfig) => {
  const { dispatch } = useMapContext();
  const prevClickFunctionRef = useRef<MapEventConfig | null>(null);

  useEffect(() => {
    // Compare the new eventFunction with the previous one
    if (
      prevClickFunctionRef.current &&
      prevClickFunctionRef.current.id === eventFunction.id &&
      prevClickFunctionRef.current.event === eventFunction.event
    ) {
      return; // Avoid re-triggering if the eventFunction is the same
    }

    // Update the reference and add the click event
    prevClickFunctionRef.current = eventFunction;
    dispatch({ type: MapActionType.ADD_CLICK_EVENT, payload: eventFunction });

    // Cleanup by removing the click event
    return () => {
      dispatch({ type: MapActionType.REMOVE_CLICK_EVENT, payload: eventFunction.id });
    };
  }, [eventFunction, dispatch]);
};
