import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import { playbackReducer } from './reducer';
import { PlaybackAction, PlaybackState, PlaybackStatus } from './types';

// Initial state
const initialState: PlaybackState = {
  timestamps: [],
  playbackStatus: PlaybackStatus.Buffering,
  currentTimestamp: 0,
  minTimestamp: 0,
  maxTimestamp: 0,
  maxPlayableTimestamp: 0,
  isLive: false,
};

// Context and dispatch setup
const PlaybackContext = createContext<{
  state: PlaybackState;
  dispatch: Dispatch<PlaybackAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const PlaybackProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(playbackReducer, initialState);

  return (
    <PlaybackContext.Provider value={{ state, dispatch }}>{children}</PlaybackContext.Provider>
  );
};

// Custom hook to access playback state
export const usePlaybackContext = () => {
  const context = useContext(PlaybackContext);
  if (!context) {
    throw new Error('usePlayback must be used within a PlaybackProvider');
  }
  return context;
};
