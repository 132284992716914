import React from 'react';
import { useLanguageSelectors } from 'src/app/reducers';
import { MapActionType, useMapContext } from 'src/@realtime/contexts/map';
import { CgRuler } from "react-icons/cg";
import { MeasureToolPanel } from './measureTool.styles';
import sassVars from 'src/styles/vars.module.scss';

export const MeasureToolButton = () => {
  const { state, dispatch } = useMapContext();
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        enableMeasureTool: enableMeasureToolLabel,
        disableMeasureTool: disableMeasureToolLabel,
      },
    },
  } = languageSelectors.getLanguage();

  const toggleMeasureTool = () => {
    dispatch({ type: MapActionType.TOGGLE_MEASURING });
  };

  return (
    <MeasureToolPanel
      onClick={toggleMeasureTool}
      title={state.isMeasuring ? disableMeasureToolLabel : enableMeasureToolLabel}
      style={{
        backgroundColor: state.isMeasuring ? sassVars.brand01Light : sassVars.brandBackground,
      }}
    >
      <CgRuler
        size={22}
        color={state.isMeasuring ? sassVars.brand01 : sassVars.text01}
        style={{ transition: 'color 0.2s ease-in-out' }}
      />
    </MeasureToolPanel>
  );
};
